import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BackgroundSvg1 from '../components/BackgroundImages/backgroundSvg1'
import Rankit from "../Images/Rankit-Logo.png"
import Navbar from '../components/Navbar';
import { Trans, useTranslation } from 'react-i18next';
import {config_data} from "../config.js";
import { useCookies } from 'react-cookie';

const Signin = () => {
  const { t } = useTranslation();
  const [signinEmail, setSigninEmail] = useState(undefined);
  const [signinPassword, setSigninPassword] = useState(undefined);
  const [cookies, setCookie] = useCookies(['rankit_token'])

  const signin = async () => {
    var filter = /^([a-zA-Z0-9_+\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if(signinEmail==undefined || !filter.test(signinEmail)) {
      toast.error(t('signin_errors_email'));
    } else if(signinPassword==undefined) {
      toast.error(t('signin_errors_password'));
    } else {
      const response = await axios.post(`${config_data['node_url']}/signin`, {email: signinEmail, password: signinPassword});
      console.log(response.data.data);
      if(response.data.result=='ok') {
        let expires = new Date()
        expires.setTime(expires.getTime() + (response.data.expires_in * 1000))
        setCookie('rankit_token', `${response.data.data.token}***${response.data.data.user_type}***${response.data.data.id}***${response.data.data.username}***${response.data.data.image}`, { secure: true, sameSite: 'none', path: '/', domain: config_data['cookie_domain'],  expires});
        //setCookie('rankit_wide_token', "1", { secure: true, sameSite: 'none', path: '/', domain: '*.rankit.it',  expires})
        setSigninEmail('');
        setSigninPassword('');
        //return false;
        //toast('Success!');
        if(response.data.data.user_type=='driver') {
          window.location.href=`${config_data['live_url']}/driver/dashboard`;
        } else if(response.data.data.user_type=='advertiser') {
          window.location.href=`${config_data['live_url']}/advertiser/dashboard`;
        } else if(response.data.data.user_type=='admin') {
          window.location.href=`${config_data['live_url']}/admin/dashboard`;
        } else if(response.data.data.user_type=='guest') {
          window.location.href=`${config_data['live_url']}/guest`;
        }
      } else if(response.data.result=='legacy') {
        window.location.href=`/recoveraccount/${response.data.data}/l`;
      } else {
        toast.error(response.data.data);
        return false;
      }
    }
  }

  return (
<div class="dark">
<Navbar/>
<section class="signIn my-5 py-5">

<div class="container my-3">
<div class="row align-items-center">

<div class="col-lg-6 picture">

   <BackgroundSvg1 />

</div>

<div class="col-lg-6 SignIn Forms px-5">

    <h3 class="title my-5">
    <Trans>{t('signin_title', {interpolation: {escapeValue: false}})}</Trans>
    </h3>


    <div class="form-floating my-4">
        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" value={signinEmail} onChange={e => setSigninEmail(e.target.value)} />
        <label for="floatingInput"><Trans>{t('signin_email', {interpolation: {escapeValue: false}})}</Trans></label>
      </div>

      <div class="form-floating my-3">
        <input type="password" class="form-control" id="floatingPassword" placeholder="Password" value={signinPassword} onChange={e => setSigninPassword(e.target.value)} />
        <label for="floatingPassword"><Trans>{t('signin_password', {interpolation: {escapeValue: false}})}</Trans></label>
      </div>

      {/*<div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
        <label class="form-check-label" for="flexCheckDefault">
        <Trans>{t('signin_remember_me', {interpolation: {escapeValue: false}})}</Trans>
        </label>
  </div>*/}

      <button class="btn my-4" onClick={() => {signin()}}><Trans>{t('signin_button', {interpolation: {escapeValue: false}})}</Trans></button>


      <div>
        <a href="/signup/driver" class="my-2 link"><Trans>{t('signin_signup', {interpolation: {escapeValue: false}})}</Trans></a>
      </div>

      <div class="my-4 ">
        <a href="/signup/guest" class="my-2 link"><Trans>{t('signin_signup_guest', {interpolation: {escapeValue: false}})}</Trans></a>
      </div>

      <div class="my-4 ">
        <a href="/forgotpassword" class="my-4 link"><Trans>{t('signin_forgot', {interpolation: {escapeValue: false}})}</Trans></a>
      </div>

</div>
</div>

</div>

    </section>
    <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
    </div>

  )
};

export default Signin;
